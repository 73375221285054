import { Box } from '@/components/atoms/Box';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { GlobalViewer } from '@/features/components/organisms/GlobalViewer';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { memo, useEffect } from 'react';
import { SelectButtons } from './SelectButtons';

type Props = {
  isGenerated: boolean;
  imageResults: ImageResult[];
  setFiles: React.Dispatch<React.SetStateAction<string[]>>;
  repeatBackgroundImage?: string;
  hasCheckIcon?: boolean;
  taskType?: string;
};
export const GlobalDownloader = memo(
  ({
    isGenerated,
    imageResults,
    setFiles,
    repeatBackgroundImage,
    hasCheckIcon = true,
    taskType,
  }: Props) => {
    const { setCheckArray } = useCheckArray();

    useEffect(() => {
      setCheckArray(imageResults.map(() => true));
    }, [imageResults, setCheckArray]);

    useEffect(() => {
      if (!imageResults.length) return;
      const urls = imageResults.map(
        (imageResult: ImageResult) => imageResult.url,
      );
      setFiles(urls);
    }, [imageResults, setFiles]);

    return (
      <Box
        sx={{
          position: 'relative',
          verticalAlign: 'top',
        }}
      >
        {!isGenerated ? (
          <></>
        ) : (
          <GlobalViewer
            imageResults={imageResults || []}
            hasAnimation={false}
            hasCheckIcon={hasCheckIcon}
            repeatBackgroundImage={repeatBackgroundImage}
            /*  2024/06/08 追加 バッチダウンロードの場合はラベルは表示しない、
            使用側で設定しても良いがひとまずここで設定 */
            label={<SelectButtons />}
            taskType={taskType}
            isDownloadDialog
          />
        )}
      </Box>
    );
  },
);
