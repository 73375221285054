import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { BatchDownloadRow } from '@/features/components/atoms/Skelton/BatchDownloadRow';
import { AccordionWrapper } from '@/features/components/molecules/AccordionWrapper';
import { memo, useCallback, useEffect, useState } from 'react';
import { BackgroundLoraBar } from './BackgroundLoraBar';
import { BackgroundLoraContent } from './BackgroundLoraContent';
import { ColorTemperatureRow } from './ColorTemperatureRow';
import { useRequest } from './hooks/useRequest';

type Props = {
  set: FeatureBatch;
  handleOpenDialog: () => void;
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  setTaskApiResponse: (task: Task | undefined) => void;
  retryId: string;
  setRetryId: (retryId: string) => void;
  batchId: string;
  setBatchId: (batchId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDoneTotal: (doneTotal: any) => void;
  handleOpenDeleteMenu: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
    hasRetry?: boolean,
  ) => void;
  deletedIds: string[];
};

/**
 * @see バッチダウンロードの生成状態とダウンロード実行ボタンを表示するコンポーネント
 */
export const DataWithRequest = memo(
  ({
    set,
    handleOpenDialog,
    handleOpenPopper,
    setTaskApiResponse,
    retryId,
    setRetryId,
    batchId,
    setBatchId,
    setDoneTotal,
    handleOpenDeleteMenu,
    deletedIds,
  }: Props): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});

    const { apiPostResponse, apiGetResponse, genStatus } = useRequest({
      setTaskApiResponse,
      set,
      retryId,
      setRetryId,
      batchId,
    });

    const handleUpdateTaskAndBatchId = useCallback(() => {
      if (apiGetResponse?.result?.status === 'IN_QUEUE') return;
      if (apiGetResponse?.result?.status === 'IN_PROGRESS') return;
      setTaskApiResponse(apiGetResponse);
      setBatchId(set.id);
    }, [apiGetResponse, set.id, setBatchId, setTaskApiResponse]);

    // const handleOpenDownloadPopper = useCallback(
    //   (e: React.MouseEvent<HTMLElement>) => {
    //     setTaskApiResponse(apiGetResponse);
    //     handleOpenPopper(e);
    //   },
    //   [apiGetResponse, handleOpenPopper, setTaskApiResponse],
    // );

    useEffect(() => {
      if (!apiGetResponse) return;
      if (
        apiGetResponse.result?.status === 'IN_QUEUE' ||
        apiGetResponse.result?.status === 'IN_PROGRESS'
      )
        return;
      if (
        apiGetResponse.result?.status === 'COMPLETED' ||
        apiGetResponse.result?.status === 'FAILED' ||
        apiGetResponse.result?.status === 'TIMEOUT'
      ) {
        setDoneTotal((prev: number) => prev + 1);
      }
    }, [apiGetResponse, setDoneTotal]);
    const [isOpenAccordion, setIsOpenAccordion] = useState(false);

    return (
      <>
        {!apiPostResponse && !apiGetResponse && <BatchDownloadRow />}
        {apiPostResponse && apiGetResponse && apiGetResponse.result && (
          <>
            {(activeFeatureName === 'colorTemperature' || activeFeatureName === 'reEditColorTemperature')
              && (
              <ColorTemperatureRow
                apiPostResponse={apiPostResponse}
                apiGetResponse={apiGetResponse}
                genStatus={genStatus}
                handleUpdateTaskAndBatchId={handleUpdateTaskAndBatchId}
                set={set}
                batchId={batchId}
                handleOpenDialog={handleOpenDialog}
              />
            )}
            {activeFeatureName === 'backgroundLora' && (
              <AccordionWrapper isOpenAccordion={isOpenAccordion}>
                <BackgroundLoraBar
                  apiPostResponse={apiPostResponse}
                  apiGetResponse={apiGetResponse}
                  genStatus={genStatus}
                  handleUpdateTaskAndBatchId={handleUpdateTaskAndBatchId}
                  isOpenAccordion={isOpenAccordion}
                  setIsOpenAccordion={setIsOpenAccordion}
                  set={set}
                  batchId={batchId}
                  handleOpenDeleteMenu={handleOpenDeleteMenu}
                  deletedIds={deletedIds}
                />
                <BackgroundLoraContent
                  set={set}
                  apiGetResponse={apiGetResponse}
                  setRetryId={setRetryId}
                  batchId={set.id}
                  setTaskApiResponse={setTaskApiResponse}
                  setIsOpenAccordion={setIsOpenAccordion}
                />
              </AccordionWrapper>
            )}
          </>
        )}
      </>
    );
  },
);
