import { Tasks } from '@/api/schemas';
import { TASK_TYPE_TO_EN_MODE_FROM_BACKEND } from '@/constants';
import { TaskCellContent } from '@/features/components/atoms/TaskCellContent';
import { TaskCellMode } from '@/features/components/atoms/TaskCellMode';
import { getModeEn } from '@/utils/task';
import { TableBody, TableCell as Td } from '@mui/material';
import { memo } from 'react';
import { Date } from './Date';
import { DownloadButton } from './DownloadButton';
import { Status } from './Status';
import { StyledTdFile, StyledTdFixed, StyledTr } from './styled';

type Props = {
  apiResponse: Tasks;
  handleSubmitTargetDownloadStep: (id: string) => void;
  handleOpenDownloadDialog: (id: string, taskType: string) => void;
  modeEn: string;
};

export const Body = memo(
  ({
    apiResponse,
    handleSubmitTargetDownloadStep,
    handleOpenDownloadDialog,
    modeEn,
  }: Props): JSX.Element => {
    return (
      <>
        <TableBody>
          {apiResponse.data.map((data) => (
            <StyledTr
              key={data.id}
              status={data.result.status}
              style={{ backgroundColor: 'red', cursor: 'pointer' }}
              onClick={(e) => {
                if (
                  !e.isPropagationStopped() &&
                  data.result.status === 'COMPLETED'
                ) {
                  handleOpenDownloadDialog(data.id, data.taskType);
                }
              }}
            >
              <StyledTdFile>
                <TaskCellMode data={data} />
              </StyledTdFile>
              <StyledTdFixed>
                <TaskCellContent data={data} />
              </StyledTdFixed>
              <StyledTdFixed>
                <Status data={data} />
              </StyledTdFixed>
              <Td>
                <Date data={data} />
              </Td>
              <Td>
                <DownloadButton
                  data={data}
                  handleSubmitTargetDownloadStep={
                    handleSubmitTargetDownloadStep
                  }
                  handleOpenDownloadDialog={handleOpenDownloadDialog}
                  modeEn={getModeEn(
                    data.taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND,
                  )}
                />
              </Td>
            </StyledTr>
          ))}
        </TableBody>
      </>
    );
  },
);
