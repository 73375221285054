import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Tooltip } from '@/components/atoms/Tooltip';
import {
  StyledBoxHeight24,
  StyledBoxHeight5,
  StyledFlex,
  StyledFlexGap10,
  StyledFlexNowrap,
  StyledFont,
} from '@/components/styled';
import {
  TASK_TYPE_TO_EN_MODE_FROM_BACKEND,
  TASK_TYPE_TO_JP_MODE_FROM_BACKEND,
} from '@/constants';
import {
  StyledImage,
  StyledImageThumbnail,
} from '@/features/Gallery/uniques/styled';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { StyledSmallIconScale } from '@/features/components/styled/icon';
import { useIcons } from '@/hooks/utils/useIcons';
import { getModeEn, getModeJp } from '@/utils/task';
import { memo } from 'react';
import { StyledFileNameContainer, StyledFileTextEllipsis } from './styled';

type Props = {
  data: Task;
  isTray?: boolean;
};

/**
 * タスクのモードを表示するコンポーネント
 * ギャラリーやタスクトレイ、モーダルダウンロードにて使用する汎用コンポーネント
 */
export const TaskCellMode = memo(({ data, isTray }: Props): JSX.Element => {
  const { returnIcon } = useIcons();

  const modeEn = getModeEn(data.taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND)
  const modeJp = getModeJp(data.taskType as keyof typeof TASK_TYPE_TO_JP_MODE_FROM_BACKEND)

  return (
    <StyledFlex
      style={{
        position: 'relative',
        width: isTray ? 196 : '100%',
      }}
    >
      <StyledFlexGap10
        style={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box>
          {data.originalImageUrl && (
            <StyledImageThumbnail>
              <ImageSpinner
                width={18}
                height={18}
                padding={0}
                hasBackground={false}
                src={`${data.originalImageUrl}&d=56x56`}
                mode="beater"
              >
                <StyledImage
                  src={`${data.originalImageUrl}&d=56x56`}
                  width={56}
                  height={56}
                  alt="data.originalImageUrl"
                />
              </ImageSpinner>
            </StyledImageThumbnail>
          )}
          {!data.originalImageUrl && (
            <StyledImageThumbnail>no image</StyledImageThumbnail>
          )}
        </Box>
        <StyledFileNameContainer isTray={isTray}>
          <StyledFlexNowrap style={{ alignItems: 'center' }}>
            <StyledBoxHeight24>
              <StyledSmallIconScale sx={{ transformOrigin: 'left center' }}>
                {returnIcon(modeEn, '#000')}
              </StyledSmallIconScale>
            </StyledBoxHeight24>
            <StyledFlex>
              <StyledFont>{modeJp}</StyledFont>
            </StyledFlex>
          </StyledFlexNowrap>
          {!isTray && (
            <Box style={{ position: 'relative', width: 200 }}>
              <StyledBoxHeight5 />
              <StyledFileTextEllipsis status={data.result.status}>
                <Tooltip
                  title={data.originalImageFileName}
                  placement="bottom-start"
                >
                  {/* 色温度の場合はセット名にする、以後別のバッチが来た場合は対応する */}
                  {data.taskType === 'generate_color_correction'
                    ? data.displayName
                    : data.originalImageFileName}
                </Tooltip>
              </StyledFileTextEllipsis>
            </Box>
          )}
        </StyledFileNameContainer>
      </StyledFlexGap10>
    </StyledFlex>
  );
});
