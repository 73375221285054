// import { useSnackbar } from '@/hooks/global/useSnackbar';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import {
  StyledFlexCenter,
  StyledFlexGap10,
  StyledGridGap25,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { initialDataParamState } from '@/features/ReEditColorTemperature/initial';
import { useError } from '@/hooks/global/useError';
import { memo, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useBatchOptionalContext } from '../../hooks/useBatchOptionalContext';
import { useReEditAtom } from '../hooks/useReEditAtom';
import { useTaskData } from '../hooks/useTaskData';
import { styledVerticalAlignBottom } from './styled/vanilla.css';

// MEMO: BatchUploadのButtonsをベースに作成。最適化の余地あり
export const Buttons = memo(() => {
  const { logger } = useError();

  // TODO: 既存機能との兼ね合いで仕方なく使っている。時間のあるときに使わない形に整備する
  const {
    activateTargetStep,
    updateFeatureDataArray,
  } = useFeaturesContext({});
  // TODO: 既存機能との兼ね合いで仕方なく使っている。時間のあるときに使わない形に整備する
  const { setIsBatchGenerating } = useBatchOptionalContext();

  const { targetOriginalImages, maskImagesMap, tmpDisplayName } = useReEditAtom();
  const { taskData } = useTaskData();

  // TODO: batchDownloadだけ既存画面を利用しているのでfeatureBatchSetを使っている。使わない形に修正する。
  const updateSets = useMemo(() => {
    if (
      !taskData ||
      !taskData.refImage ||
      !taskData.refImage.maskImage ||
      maskImagesMap === undefined ||
      targetOriginalImages.length !== Object.keys(maskImagesMap).length
    ) return undefined

    const originalImagesForSet = targetOriginalImages.map((targetOriginalImage, index) => {
      // MEMO: なぜかESLintでエラーが出るので細かく条件チェックをしている
      if (
        maskImagesMap === undefined ||
        maskImagesMap[index] === undefined ||
        maskImagesMap[index]?.maskId === undefined
      ) throw new Error("maskImagesMap?.[index]?.id is undefined");

      return {
        fileName: targetOriginalImage.inputImage.fileName,
        url: targetOriginalImage.inputImage.originalImageUrl,
        inputImageId: targetOriginalImage.inputImage.id,
        maskImageId: maskImagesMap[index]?.maskId, // MEMO: 条件分岐を書いているにもかかわらず、 `maskImageId: maskImagesMap[index].maskId` を使うとエラーが出る
      }
    })

    const set = {
      setName: tmpDisplayName || '',
      refImage: {
        refImageId: taskData.refImage?.inputImage.id,
        maskImageId: taskData.refImage?.maskImage?.id,
        refUrl: taskData.refImageUrl, // MEMO: 本質的には不要だが定義しておかないとエラーになるので定義しておく（型が整備されていないので仕方なし）
      },
      originalImages: originalImagesForSet,
      mainParam: initialDataParamState,

      // MEMO: 以下、本質的には不要だが定義しておかないとエラーになるので仮で定義（型が整備されていないので仕方なし）
      id: uuidv4(),
      uploadedAt: Date.now(),
      images: [],
      fileNames: [],
      presetImage: { id: '' },
      refSam: {},
      isCurrent: false,
    };

    return [set]
  }, [
    targetOriginalImages,
    maskImagesMap,
    tmpDisplayName,
    taskData,
  ])

  const handleReEdit = useCallback(() => {
    try {
      if (taskData === undefined) throw new Error('taskData is undefined');
      if (updateSets === undefined) throw new Error('updateSets is undefined');

      updateFeatureDataArray('batch', updateSets);
      setIsBatchGenerating(true);
      activateTargetStep('batchDownload');
    } catch (error) {
      logger({error});
    }
  }, [
    taskData,
    activateTargetStep,
    updateFeatureDataArray,
    updateSets,
    setIsBatchGenerating,
    logger,
  ]);

  const isDisabledButton = useMemo(() => {
    if (maskImagesMap === undefined) return true

    return targetOriginalImages.length !== Object.keys(maskImagesMap).length;
  }, [
    maskImagesMap,
    targetOriginalImages,
  ])

  const { activateTargetFeature} = useFeaturesContext({});

  return (
    <>
      <StyledFlexCenter>
        <Box className={styledVerticalAlignBottom}>
          <StyledGridGap25>
            <StyledFlexGap10 style={{ alignItems: 'flex-start' }}>
              <Box>※</Box>
              <Box style={{ width: '250px', fontSize: '14px' }}>
                すべての画像の領域選択が完了すると、生成へ進むことができます。
              </Box>
            </StyledFlexGap10>
              <Button
                mode="border"
                sx={{
                  width: '272px',
                  height: '42px',
                  color: 'var(--color-primary-main)',
                  '&:hover': {
                    cursor: 'pointer',
                    color: 'var(--color-gray-main)',
                    backgroundColor: 'transparent',
                  },
                  fontSize: '15px',
                }}
                onClick={() => activateTargetFeature('home')}
              >
                再生成を中断する
              </Button>
              <Button
                sx={{
                  fontSize: '15px',
                }}
                width={272}
                height={42}
                onClick={handleReEdit}
                disabled={isDisabledButton}
              >
                再生成
              </Button>
            <Box />
          </StyledGridGap25>
        </Box>
        {/* <ConfirmUnsettledSetDialog
          isOpenDialog={isAlertDialog}
          handleCloseDialog={() => setIsAlertDialog(false)}
          handleSubmit={() => {
            setIsAlertDialog(false);
            activateTargetStep('batchDownload');
          }}
        /> */}
      </StyledFlexCenter>
    </>
  );
});
