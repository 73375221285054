import { PageInfoSchema } from '@/api/schemas/common';
import { UserSchema } from '@/api/schemas/users';
import { z } from 'zod';

const TaskResultImageSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  downloadedAt: z.string().nullable(),
  url: z.string(),
  url72X72: z.string().optional().nullable(),
  url120X120: z.string().optional().nullable(),
  url200X200: z.string().optional().nullable(),
  url600X600: z.string().optional().nullable(),
});

export const TaskResultSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  taskId: z.string(),
  status: z.string(),
  progress: z.number().nullable(),
  message: z.string().nullable(),
  resultImages: z.array(TaskResultImageSchema),
  errorMessage: z.string().nullable(),
});

const TaskParametersSchema = z
  .object({
    color: z.string().optional().nullable(),
    withShadow: z.boolean().optional().nullable(),
    addBokeh: z.string().optional().nullable(),
    noBackground: z.boolean().optional().nullable(),
    presetId: z.string().optional().nullable(),
  })
  .nullable();

const OriginalImageImageSchema = z.object({
  id: z.string(),
  // MEMO: 以下、現状使ってないものはコメントアウト
  // createdAt: z.string(),
  // updatedAt: z.string(),
  // userId: z.string(),
  // organizationId: z.string(),
  // originalImageFormat: z.string(),
  fileName: z.string().nullable(),
  // hash: z.string(),
  originalImageUrl: z.string().url(),
});

const TaskOriginalImageSchema =
  z.object({
    // MEMO: 以下、現状使ってないものはコメントアウト
    // id: z.string(),
    // createdAt: z.string(),
    // updatedAt: z.string(),
    // userId: z.string(),
    // organizationId: z.string(),
    inputImage: OriginalImageImageSchema,
    maskImage: OriginalImageImageSchema.nullable(),
  })

const TaskRefImageSchema = z.object({
  // MEMO: 以下、現状使ってないものはコメントアウト
  // id: z.string(),
  // createdAt: z.string(),
  // updatedAt: z.string(),
  // userId: z.string(),
  // organizationId: z.string(),
  inputImage: OriginalImageImageSchema,
  maskImage: OriginalImageImageSchema.nullable(),
  keepMaskImage: OriginalImageImageSchema.nullable(),
})

export const TaskSchema = z.object({
  id: z.string(),
  displayName: z.string().optional().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deletedAt: z.string().nullable(),
  userId: z.string(),
  user: UserSchema,
  organizationId: z.string(),
  generationBatchId: z.string().nullable().optional(),
  category: z.string(),
  taskType: z.string(),
  originalImageUrl: z.string(),
  originalImageFileName: z.string(),
  originalImageFormat: z.string(),
  maskImageUrl: z.string().nullable(),
  refImageUrl: z.string().nullable(),
  refMaskImageUrl: z.string().nullable(),
  keepMaskImageUrl: z.string().nullable(),
  nSample: z.number(),
  rough: z.boolean(),
  parameters: TaskParametersSchema,
  result: TaskResultSchema,
  originalImages: z.array(TaskOriginalImageSchema),
  refImage: TaskRefImageSchema.nullable(),
});

export const TasksSchema = z.object({
  data: z.array(TaskSchema),
  pageInfo: PageInfoSchema,
});

// {data: [{id: "01J2DQ12BYHQJS4A04K032Y1KS", created_at: "2024-07-10T15:59:24.320197+09:00",…}],…}

export const ExampleSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  presetId: z.string(),
  url: z.string().url(),
  position: z.number(),
});

export const PresetSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  displayName: z.string(),
  examples: z.array(ExampleSchema),
  allowedTaskTypes: z.nullable(z.array(z.string())), // allowed_task_types -> allowedTaskTypes
});

export const PresetsSchema = z.object({
  data: z.array(PresetSchema),
  pageInfo: PageInfoSchema,
});

export type TaskResultImage = z.infer<typeof TaskResultImageSchema>;
export type TaskOriginalImage = z.infer<typeof TaskOriginalImageSchema>;
export type TaskResult = z.infer<typeof TaskResultSchema>;
export type TaskParameters = z.infer<typeof TaskParametersSchema>;
export type Task = z.infer<typeof TaskSchema>;
export type Tasks = z.infer<typeof TasksSchema>;
export type Preset = z.infer<typeof PresetSchema>;
export type Presets = z.infer<typeof PresetsSchema>;
